$(function() {

    // ページスクロール処理
    $('a[href^="#"]').click(function() {
        // スクロールの速度
        var speed = 400; // ミリ秒
        // アンカーの値取得
        var href = $(this).attr("href");
        // 移動先を取得
        var target = $(href == "#" || href == "" ? 'html' : href);
        // 移動先を数値で取得
        var position = target.offset().top;
        // スムーススクロール
        $('body,html').animate({
            scrollTop: position
        }, speed, 'easeInOutQuad');
        return false;
    });

    // 外部サイトは新規タブ
    // $('a[href^="http"]').not('[href^="https"]').not('[href^="http://example.com"]').click(function() {
    //     window.open(this.href, '_blank');
    //     return false;
    // }).addClass('external');

    //ページ上部
    $('.totop').hide();
    $(window).bind("scroll", function() {
        if ($(this).scrollTop() > 500) {
            $('.totop').fadeIn();
        } else {
            $('.totop').fadeOut();
        }
        // フッター固定
        scrollHeight = $(document).height(); // ドキュメントの高さ
        scrollPosition = $(window).height() + $(window).scrollTop(); // 現在のトップからの位置
        footHeight = $(".globalFooter").innerHeight(); // フッターの内側の高さ
        if (scrollHeight - scrollPosition <= footHeight) { // フッターの高さの位置にはいったら
            $('.totop').css({
                "position": "absolute",
                "bottom": ((footHeight) + 30)
            });
        } else {
            $('.totop').css({
                "position": "fixed",
                "bottom": "30px"
            });
        }
    });

    // 表示ページナビにclass付加
    var activeUrl = location.pathname.split("/")[1];
    navList = $(".globalNav_item").find("a");

    navList.each(function() {
        if ($(this).attr("href").split("/")[1] == activeUrl) {
            $(this).parent("li").addClass("current");
        };
    });

    // MatchHeight
    $('.js-mh > * > a').matchHeight();

    // Image hover
    // $('.img_hover img').hover(function() {
    //     $(this).attr('src', $(this).attr('src').replace('_off', '_on'));
    // }, function() {
    //     $(this).attr('src', $(this).attr('src').replace('_on', '_off'));
    // });

    // Colorbox
    // $(".youtube").colorbox({
    //     iframe: true,
    //     innerWidth: 853,
    //     innerHeight: 480
    // });
    //   $('.colorbox').colorbox({
    //   maxWidth: "90%"
    // });
    // フレームの高さをウィンドウサイズに合わせる。
    // var wHeight = $(window).height();
    // $(window).resize(function() {
    //     $(".slideFrame").height($(window).height());
    // }).resize();

    //スクロール追従メニュー
    // var side = $(".sideMenu");
    // var main = $(".content");

    // var window_min = 0;
    // var window_max = 0;

    // var min_move = main.offset().top;
    // var max_move = main.offset().top + main.height() - side.height() - 2 * parseInt(side.css("top"));
    // var threshold_offset = 50;
    // var window_min = min_move - threshold_offset;
    // var window_max = max_move + side.height() + threshold_offset;

    // function window_scroll() {
    //     if ($(window).scrollTop() >= window_min && $(window).scrollTop() < window_max) {
    //         container_move();
    //     }
    // }
    // $(window).bind("scroll", window_scroll);


    // function container_move() {
    //     if ($(window).scrollTop() >= min_move && $(window).scrollTop() <= max_move) {

    //         var margin_top = $(window).scrollTop() - min_move;
    //         side.animate({
    //             "margin-top": margin_top
    //         }, {
    //             duration: 500,
    //             queue: false
    //         });
    //     } else if ($(window).scrollTop() <= min_move) {
    //         side.css("margin-top", 0);
    //     } else if ($(window).scrollTop() > max_move) {
    //         side.css("margin-top", max_move - min_move + "px");
    //     }
    // }
    // container_move();

    // Slide Toggle Menu
    // $('.globalNav_items').hide();
    // $('.js-globalNav').bind('touchstart', function() {
    //   $('.globalNav_items').slideToggle('slow');
    // });

    // Slide Toggle Nav
    // var isTouch = ('ontouchstart' in window) ? 'touchstart' : 'click';
    //   $('.menuBtn').on(isTouch, function() {
    //       $('.gNav_items').slideToggle('slow');
    //       $(this).toggleClass('open');
    //       return false;
    //     });


    // Slide Toggle Index
    // var tglBtn = $('.js-toggleBtn');
    // var tglPanel = $('.js-togglePanel');
    // tglPanel.hide();
    // tglBtn.bind('touchstart', function() {
    //   $(this).next(tglPanel).slideToggle('slow');
    //   $(this).toggleClass('faqBox_title-close');
    // });
});
